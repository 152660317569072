import axios from 'axios';
import { AuthPageActions } from './AuthPageReducer';
import { toast } from 'react-toastify';

export const adminLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(AuthPageActions.loginRequest());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/admin/login`,
        { email, password }
      );
      console.log(response?.data?.token);
      localStorage.setItem('admin-token', response?.data?.token);

      dispatch(AuthPageActions.loginSuccess(response.data));
      toast.success(response.data.message);
    } catch (error) {
      dispatch(AuthPageActions.loginFailure(error.message));
      toast.error(error?.response?.data?.message);
    }
  };
