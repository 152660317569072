import axios from 'axios';
import { SubscriptionsAction } from './SubscriptionsReducer';
import { subscriptions } from '../../Shared/Subscriptions';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSubscriptions = (email, password) => async (dispatch) => {
  try {
    dispatch(SubscriptionsAction.GetSubscriptionsRequest());
    const response = subscriptions;
    const _response = await axios.get(`${API_BASE_URL}/subscriptions`);
    console.log({ RESPONSE: _response.data.data });
    dispatch(SubscriptionsAction.GetSubscriptionsSuccess(_response.data.data));
  } catch (error) {
    dispatch(SubscriptionsAction.GetSubscriptionsFailure(error.message));
    alert(error.message);
  }
};
