import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  login: {},
};

const authPageSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest: (state, action) => {
      state.loading = true;
      state.error = false;
      state.login = {};
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.login = action.payload;
      state.error = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.login = false;
      state.login = {};
    },
  },
});

export const AuthPageActions = authPageSlice.actions;
export default authPageSlice;
