import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { deleteOrder } from '../../../Redux/Orders/OrdersActions';
import { customStyles } from '../../customStyle';
const DeleteOrderModal = ({ isOpen, closeModal, data }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteOrder(data.orderId));
    closeModal();
  };
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Order Modal"
        style={customStyles}
      >
        <div className="flex flex-col gap-y-5 p-10">
          <h1 className="text-3xl">
            Are you sure you want to delete this order?
          </h1>
          <div className="flex flex-row gap-x-5">
            <button
              className="bg-red-500 text-white p-2 rounded-lg"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 text-white p-2 rounded-lg"
              onClick={() => handleDelete()}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteOrderModal;
